<script setup lang="ts">
import {pretty, prettyRound} from "~/utils/pretty-num.js";
import Menu from "~/components/Menu.vue";
import {ref} from "vue";

let timeleft = ref("");

const ending = Math.floor((+new Date(2025, 0, 1, 0, 0, 0)) / 1000)

function updateTimeLeft() {
    let diff = ending - Math.floor((+Date.now()) / 1000);

    let d = Math.floor(diff / 86400);
    diff -= d * 86400;

    let h = Math.floor(diff / 3600);
    diff -= h * 3600;

    let m = Math.floor(diff / 60);
    diff -= m * 60;

    let s = diff;

    timeleft.value = `${d}d${h}h${m}m${s}s`;
}


updateTimeLeft()
setInterval(updateTimeLeft, 1000)

</script>

<template>
    <div class="layout--default">
        <div class="header u-container">
            <div class="header__fixed u-flex u-flex--justify-between u-flex--align-center">
                <div class="header__user">
                    <!--<div class="header__avatar" :style="$store.user?.photo ? `background-image: url(${$store.user?.photo})` : ''"></div>-->
                    <div class="header__username">{{ $store.username }}</div>
                </div>

                <div class="header__balance">
                    {{ pretty($store.user?.balance || 0) }}
                    <span class="u-text-muted">CVT</span>
                </div>
            </div>
            <div class="header__claim u-flex u-flex--justify-between u-flex--align-center">
                <div>
                    <div class="u-h--uppercase" style="font-size: 12px">Mint Time Left</div>
                    <div class="header__claim-amount" style="font-size: 21px">
                        {{ timeleft }}
                    </div>
                </div>
                <nuxt-link to="/game" class="button button--fancy" v-haptic-feedback="'medium'">Earn</nuxt-link>
            </div>
        </div>

        <slot />

        <Menu/>
    </div>
</template>
